import { sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import Firebase from "./Firebase";

class Auth {
    getAuth() {
        return Firebase.getAuth();
    }

    // Sign up with email and password
    // Returns null if the user has successfully signed up, an error otherwise
    async signUpWithEmailAndPassword(email: string, password: string) {
        const auth = this.getAuth();
        var res: string | null = null;
        if (auth) {
            await createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log("user: ", user);
                    res = null;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("errorCode: ", errorCode);
                    console.log("errorMessage: ", errorMessage);
                    res = errorMessage;
                });
        }
        return res;
    }

    // Sign in with email and password
    // Returns null if the user has successfully signed in, an error otherwise
    async signInWithEmailAndPassword(email: string, password: string) {
        const auth = this.getAuth();
        var res: string | null = null;
        if (auth) {
            await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log("user: ", user);
                    res = null;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("errorCode: ", errorCode);
                    console.log("errorMessage: ", errorMessage);
                    res = errorMessage;
                });
        }
        return res;
    }

    // Sign out
    // Returns null if the user has successfully signed out, an error otherwise
    async signOut() {
        const auth = this.getAuth();
        var res: string | null = null;
        if (auth) {
            await signOut(auth)
                .then(() => {
                    // Sign-out successful.
                    res = null;
                })
                .catch((error) => {
                    // An error happened.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("errorCode: ", errorCode);
                    console.log("errorMessage: ", errorMessage);
                    res = errorMessage;
                });
        }
        return res;
    }

    // Request a password reset email
    // Returns null if the email has been sent, an error otherwise
    async sendPasswordResetEmail(email: string) {
        const auth = this.getAuth();
        var res: string | null = null;
        if (auth) {
            await sendPasswordResetEmail(auth, email)
                .then(() => {
                    // Password reset email sent!
                    res = null;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("errorCode: ", errorCode);
                    console.log("errorMessage: ", errorMessage);
                    res = errorMessage;
                });
        }
        return res;
    }

    // Function to check if user is signed in
    // Returns true if the user is signed in, false otherwise
    isSignedIn() {
        const auth = this.getAuth();
        if (auth) {
            const user = auth.currentUser;
            if (user) {
                return true;
            }
        }
        return false;
    }

    // Function to get the current user email
    // Returns the user email if the user is signed in, null otherwise
    getCurrentUserEmail() {
        const auth = this.getAuth();
        if (auth) {
            const user = auth.currentUser;
            if (user) {
                return user.email;
            }
        }
    }

    // Function to get the current user id
    // Returns the user id if the user is signed in, empty string otherwise
    getCurrentUserId() {
        if (this.isSignedIn()) {
            const auth = this.getAuth();
            if (auth) {
                const user = auth.currentUser;
                if (user) {
                    return user.uid;
                }
            }
        }
    }

    // Function to check if a password is strong enough
    // Returns true if the password is strong enough, false otherwise
    // 8 characters, 1 uppercase, 1 lowercase, 1 number
    isPasswordStrongEnough(password: string) {
        const strongEnoughRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        );
        return strongEnoughRegex.test(password);
    }
}

export default new Auth();